<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item label="Subject">
          <a-select
            show-search
            :filter-option="filterOption"
            @change="(e) => handleChange(e, 'id_mata_pelajaran')"
            class="w-100"
            size="large"
            :value="dataCoreCompetence.id_mata_pelajaran"
          >
            <a-select-option
              v-for="mapel in subjectData"
              :key="mapel.id"
              :value="mapel.id"
              >{{ mapel.nama }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Level">
          <a-select
            size="large"
            class="w-100"
            :value="dataCoreCompetence.id_level"
            @change="(e) => handleChange(e, 'id_level')"
          >
            <a-select-option
              v-for="item in levelData"
              :key="item.key"
              :value="item.id"
            >
              {{ item.nama }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Type">
          <a-select
            size="large"
            class="w-100"
            :value="dataCoreCompetence.tipe"
            @change="(e) => handleChange(e, 'tipe')"
          >
            <a-select-option v-for="item in tipeData" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Name">
          <a-textarea
            class="w-100"
            :value="dataCoreCompetence.nama"
            @change="(e) => handleChange(e.target.value, 'nama')"
            :rows="4"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const tipeData = ['spiritual', 'sosial', 'pengetahuan', 'keterampilan']
export default {
  props: {
    dataCoreCompetence: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tipeData,
      subjectData: [],
      levelData: [],
    }
  },
  methods: {
    fetchDataSubject(params = { page: 'all', order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.$store.dispatch('master/FETCH_SUBJECT', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.subjectData = res.dataTable.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    fetchDataLevel(params = { page: 'all', order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.$store.dispatch('master/FETCH_LEVEL', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.levelData = res.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  mounted() {
    this.fetchDataSubject()
    this.fetchDataLevel()
  },
}
</script>

<style>
</style>
